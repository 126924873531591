<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">{{ partnerType.name }}</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
    <router-link :to="{name: 'partner-types-edit',params: {'id': partnerType.id}}" class="header-icon header-icon-2"
        v-if="hasPermission('partner_type_update')">
        <i class="far fa-edit"></i>
    </router-link>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="mb-1 card-style shadow-0 d-flex justify-content-end">
        <div>
            <a href="#" class="chip chip-s bg-white">
                <i v-if="partnerType.status == 'enabled'" class="fa fa-check color-white primary-light-bg"></i>
                <i v-if="partnerType.status == 'disabled'" class="fa fa-times color-white primary-alt-base-bg "></i>
                <strong class="color-black font-600 font-14 text-capitalize letterSpacing">{{partnerType.status}}</strong>
            </a>
        </div>
    </div>
    <div class="card card-style">
        <div class="content">

            <div v-if="partnerType.category">
                <label class="text-dark letterSpacing font-13 font-600 ms-2 mb-1"
                    style="opacity: 1;background-color: transparent;">
                    Partner Categoty
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-1 text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ partnerType.category }}
                </div>
            </div>
            <div v-if="partnerType.commission_percentage">
                <label class="text-dark letterSpacing font-13 font-600 ms-2 mb-1"
                    style="opacity: 1;background-color: transparent;">
                    Commission Percentage
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-1 text-success"
                    style="border:none; border-radius: 30px !important;background-color: rgb(214, 255, 231);">
                    {{partnerType.commission_percentage}} %
                </div>
            </div>
            <div>
                <label class="text-dark letterSpacing font-13 font-600 ms-2 mb-1"
                    style="opacity: 1;background-color: transparent;">
                    Reward Percentage
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-1 text-success"
                    style="border:none; border-radius: 30px !important;background-color: rgb(214, 255, 231);">
                    {{partnerType.reward_percentage}} %
                </div>
            </div>
            <div v-if="partnerType.country ">
                <label class="text-dark letterSpacing font-13 font-600 ms-2 mb-1"
                    style="opacity: 1;background-color: transparent;">
                    Country
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-1  text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{partnerType.country ? partnerType.country.emoji : ""}} &nbsp; {{partnerType.country ?
                    partnerType.country.name : ""}}
                </div>
            </div>
            <div v-if="partnerType.referral_prefix ">
                <label class="text-dark letterSpacing font-13 font-600 ms-2 mb-1"
                    style="opacity: 1;background-color: transparent;">
                    Referral Prefix
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-0 text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ partnerType.referral_prefix }}
                </div>
            </div>
        </div>
    </div>
</div>