import api from "@/common/services/api";

export default new class {
    baseUrl = "";
    
    getSelectData(data) {
        return api.instance.get("/get-select-data", {params: data});
    }

    getPartnerTypes(data) {
        return api.instance.get("/partner-types",{params: data});
    }

    savePartnerType(data) {
        return api.instance.post("/partner-types",data);
    }

    getPartnerTypesData(id) {
        return api.instance.get("/partner-types/" + id);
    }

    updatePartnerType(id, data) {
        return api.instance.patch("/partner-types/"+id, data);
    }
};