import TypesData from "../../services";

export default {
    data() {
        return {
            searchedResultShow: false,
            types: {},
            country_id: (this.$route.query.country ? this.$route.query.country : this.defaultFilterCountryId()),
            search: "",
            hasSuperAdmin: false,
        }
    },
    watch: {
        'country_id': function () {
            this.$router.replace({query: { country: this.country_id }});
            this.getPartnerType();
        }
    },
    mounted: function mounted() {
        if ('user_type' in this.$store.state.user && this.$store.state.user.user_type == "super_admin") {
            this.hasSuperAdmin = true;
        }
        this.getPartnerType();
    },
    methods: {
        getPartnerType() {
            this.showLoader(true);
            TypesData.getPartnerTypes({"country_id": this.country_id,"search": this.search}).then(response => {
                this.hideLoader();
                this.types = response.data.data;
            });
        },
        filterUpdate() {
            this.getPartnerType();
            this.searchedResultShow = true;
        },
        filterClear() {
            this.search = "";
            this.getPartnerType();
            this.searchedResultShow = false;
        },
    }
}