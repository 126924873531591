import typesData from "../../services";

export default {
    data() {
        return {
            partner: {},
            selectData: {},
            formValidations: {
                name: v => !!v || "Name is required",
                commission_percentage: v => v ?
                    ((/^(99(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/).test(v) || "Commission Percentage Must be Valid between 0 to 99") :
                    "Commission percentage is required",
                reward_percentage: v => v ?
                    ((/^(99(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/).test(v) || "Reward Percentage Must be Valid between 0 to 99") :
                    "Reward percentage is required",
                referral_prefix: v => v ? ((/^[A-Za-z]{4}$/).test(v) || "Referral prefix allow only 4 letters.") :
                    "Referral prefix is required",
                partner_category: v => !!v || "Country is required",
                country_id: v => !!v || "Country is required",
            },
            isInvited: false,
            invited: {}
        }
    },
    mounted() {
        var select = {
            'type': ['partnerTypes', 'partnerCategories']
        };
        typesData.getSelectData(select).then(response => {
            this.selectData = response.data;
        });
        typesData.getPartnerTypesData(this.$route.params.id).then(response => {
            this.partner = response.data.data;
        });
    },
    methods: {
        submitForm() {
            this.showLoader(true);
            this.$popper.wait("Updating...");
            typesData.updatePartnerType(this.partner.id, this.partner).then(response => {
                this.$popper.success(response.data.message);
                this.hideLoader();
            });
        },
    }
}