<app-header menu="no">
    <h1 class="header-title ms-5 text-capitalize" style="letter-spacing:0.5px;">Add Partner Type</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="card card-style">
        <div class="content">
            <div class="col-12 text-center">
                <p class="color-highlight mb-0"></p>
            </div>

            <Form novalidate @submit="submitForm" :validation-schema="formValidations" v-slot="{ errors }" autocomplete="off">
                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.name}">
                    <i class="fa fa-user"></i>
                    <Field as="input" type="text" name="name" v-model="partner.name" class="form-control" id="name" placeholder="Partner Type Name"></Field>
                    <label for="name" class="color-primary-dark font-13 font-600">Partner Type Name</label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.name}}
                    </template>
                </form-control>

                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.commission_percentage}">
                    <i class="fa fa-percentage"></i>
                    <Field as="input" type="number" name="commission_percentage" v-model="partner.commission_percentage" class="form-control" id="commissionPercentage" placeholder="Commission Percentage"></Field>
                    <label for="commissionPercentage" class="color-primary-dark font-13 font-600">Commission Percentage</label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.commission_percentage}}
                    </template>
                </form-control>

                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.reward_percentage}">
                    <i class="fa fa-percentage"></i>
                    <Field as="input" type="number" name="reward_percentage" v-model="partner.reward_percentage" class="form-control" id="rewardPercentage" placeholder="Reward Percentage"></Field>
                    <label for="rewardPercentage" class="color-primary-dark font-13 font-600">Reward Percentage</label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.reward_percentage}}
                    </template>
                </form-control>

                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.referral_prefix}">
                    <i class="fa fa-qrcode"></i>
                    <Field as="input" name="referral_prefix" type="text" v-model="partner.referral_prefix" class="form-control" id="referral_prefix" placeholder="Referral Prefix"></Field>
                    <label for="referral_prefix" class="color-primary-dark font-13 font-600">Referral Prefix</label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.referral_prefix}}
                    </template>
                </form-control>

                <form-control class="validate-field" :class="{'is-invalid' : errors.partner_category}">
                    <app-select name="partner_category" placeholder="Partner Category" :options="selectData.partnerCategories" value-prop="id" v-model="partner.partner_category_id">
                        <template v-slot:default="slotProps">
                            {{ slotProps.item.text }}
                        </template>
                    </app-select>
                    <template v-slot:error>{{errors.partner_category}}</template>
                </form-control>

                <form-control class="validate-field" :class="{'is-invalid' : errors.parent_partner_type}">
                    <app-select name="parent_partner_type" placeholder="Parent Partner Type" :options="selectData.partnerTypes" value-prop="id" v-model="partner.parent_partner_type">
                        <template v-slot:default="slotProps">
                            {{ slotProps.item.text }}
                        </template>
                    </app-select>
                    <template v-slot:error>{{errors.parent_partner_type}}</template>
                </form-control>

                <form-control class="validate-field input-style-active" :class="{'is-invalid' : errors.country_id}">
                    <app-select name="country_id" placeholder="Country" :options="$store.state.selectData.countries" value-prop="id" v-model="partner.country_id">
                        <template v-slot:default="slotProps">
                            {{ slotProps.item.emoji }} &nbsp; {{ slotProps.item.name }}
                        </template>
                    </app-select>
                    <template v-slot:error>{{errors.country_id}}</template>
                </form-control>

                <div class="d-flex justify-content-center mt-4">
                    <button class="btn btn-ripple btn btn-ripple-l font-600 font-13  primary-light-bg letterSpacing rounded-s">
                        Create
                    </button>
                </div>
            </Form>
        </div>
    </div>
</div>