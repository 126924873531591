import typesData from "../../services";

export default {
    data() {
        return {
            partnerType: {},
        }
    },
    mounted() {
        typesData.getPartnerTypesData(this.$route.params.id).then(response => {
            this.partnerType = response.data.data;
            console.log(response.data.data)
        });
    }
}